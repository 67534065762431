.modal-header{
  padding: 10px 15px;
}
.content-box{
  background: #eee;
  margin: 15px 5px;
  padding: 5px;
  border-radius: 7px;
}
.content-box span {
  display: block;
  margin-bottom: 10px;
  font-size: 13px;
}
.dicon{
  color: #0079f1;
  margin-right: 5px;
}
.closebtn{
  background: #0079f1 !important;
  color: #fff !important;
}
.modal-footer{
  padding: 0px 15px !important;
}
.modal-maxwidth{
  max-width: 300px !important;
  text-align: center;
}